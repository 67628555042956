<template>
  <div style="width:100%;">
    <div v-if="!tvshow">
      <v-progress-circular color="primary" :indeterminate="true" />
    </div>
    <div v-if="tvshow">
      <div
        class="backdrop"
        :style="'background-image:url(https://image.tmdb.org/t/p/w500' + tvshow.backdrop_path + ');'"
      ></div>
      <div style="position:absolute;top:10px;left:10px;">
        <v-btn
          :style="{marginBottom: '10px', textShadow: $vuetify.theme.dark ? '1px 1px #000' : '1px 1px #fff'}"
          @click="goBack"
          text
        >
          <v-icon style="margin-right: 10px;">fa-chevron-left</v-icon>
          <span>{{ $t("tvshows.back") }}</span>
        </v-btn>
      </div>
      <div style="padding:10px;margin-top:-140px;">
        <v-layout row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col cols="auto">
            <v-img
              class="poster"
              :src="'https://image.tmdb.org/t/p/w500' + tvshow.poster_path"
              width="200"
            ></v-img>
          </v-col>
          <v-col style="padding-top:130px;">
            <h1>{{ tvshow.name }}</h1>
            <p style="margin-bottom:0;">
              {{ (new Date(tvshow.first_air_date)).getFullYear() }} |
              <span
                :title="$t('tvshows.seasons')"
              >{{ tvshow.number_of_seasons }} {{ $t("tvshows.seasons") }}</span>
            </p>
            <v-chip-group>
              <v-chip v-for="genre in tvshow.genres" :key="genre.id">{{ genre.name }}</v-chip>
            </v-chip-group>
          </v-col>
        </v-layout>
        <div v-else style="text-align:center;">
          <v-img
            class="poster"
            :src="'https://image.tmdb.org/t/p/w500' + tvshow.poster_path"
            width="200"
            style="margin-left:auto;margin-right:auto;"
          ></v-img>
          <h1>{{ tvshow.title }}</h1>
          <p>
            {{ (new Date(tvshow.first_air_date)).getFullYear() }} |
            <span
                :title="$t('tvshows.seasons')"
              >{{ tvshow.number_of_seasons }} {{ $t("tvshows.seasons") }}</span>
          </p>
          <v-chip-group style="align-items:center;">
            <v-chip v-for="genre in tvshow.genres" :key="genre.id">{{ genre.name }}</v-chip>
          </v-chip-group>
        </div>
        <v-layout row wrap style="margin:10px 0 0 0;">
          <v-flex xs12 sm12 md6>
            <v-card style="margin:5px;">
              <v-card-text>
                <p>{{ tvshow.overview }}</p>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 sm12 md6>
            <v-card style="margin:5px;" v-if="row_id === null">
              <v-card-title>{{ $t("tvshows.have_you_watched_this_show") }}</v-card-title>
              <v-card-text>
                <p>{{ $t("tvshows.rating") }}</p>
                <v-layout row wrap style="margin:0;" :justify-center="$vuetify.breakpoint.xs">
                  <v-flex xs12>
                    <v-rating
                      v-model="watched_rating"
                      class="switch-center"
                      :background-color="$vuetify.theme.dark ? '#fff' : '#000'"
                      hover
                    ></v-rating>
                  </v-flex>
                </v-layout>
                <p style="margin-top:20px;">{{ $t("tvshows.watched_when") }}</p>
                <v-layout style="margin:0;" row wrap>
                  <v-flex xs12 sm6 style="padding:10px;">
                    <v-select :label="$t('tvshows.month')" v-model="watched_month" :items="months"></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 style="padding:10px;">
                    <v-select :label="$t('tvshows.year')" v-model="watched_year" :items="years"></v-select>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="savePersonalExperience">{{ $t("general.save") }}</v-btn>
              </v-card-actions>
            </v-card>
            <v-card style="margin:5px;" v-else-if="row_id && !edit_mode">
              <v-card-title>{{ $t("tvshows.personal_experience") }}</v-card-title>
              <v-card-text>
                <p>{{ $t("tvshows.rating") }}</p>
                <v-layout row wrap style="margin:0;" :justify-center="$vuetify.breakpoint.xs">
                  <v-flex xs12>
                    <v-rating
                      readonly
                      v-model="watched_rating"
                      class="switch-center"
                      :background-color="$vuetify.theme.dark ? '#fff' : '#000'"
                    ></v-rating>
                  </v-flex>
                </v-layout>
                <p style="margin-top:20px;">{{ $t("tvshows.watched_when") }}</p>
                <v-layout style="margin:0;" row wrap>
                  <v-flex xs12 sm6 style="padding:10px;">
                    <v-select
                      disabled
                      :label="$t('tvshows.month')"
                      v-model="watched_month"
                      :items="months"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 style="padding:10px;">
                    <v-select
                      disabled
                      :label="$t('tvshows.year')"
                      v-model="watched_year"
                      :items="years"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="edit_mode = true">{{ $t("general.edit") }}</v-btn>
              </v-card-actions>
            </v-card>
            <v-card style="margin:5px;" v-else-if="row_id !== null && edit_mode">
              <v-card-title>{{ $t("tvshows.have_you_watched_this_show") }}</v-card-title>
              <v-card-text>
                <p>{{ $t("tvshows.rating") }}</p>
                <v-layout row wrap style="margin:0;" :justify-center="$vuetify.breakpoint.xs">
                  <v-flex xs12>
                    <v-rating
                      v-model="watched_rating"
                      class="switch-center"
                      :background-color="$vuetify.theme.dark ? '#fff' : '#000'"
                      hover
                    ></v-rating>
                  </v-flex>
                </v-layout>
                <p style="margin-top:20px;">{{ $t("tvshows.watched_when") }}</p>
                <v-layout style="margin:0;" row wrap>
                  <v-flex xs12 sm6 style="padding:10px;">
                    <v-select :label="$t('tvshows.month')" v-model="watched_month" :items="months"></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 style="padding:10px;">
                    <v-select :label="$t('tvshows.year')" v-model="watched_year" :items="years"></v-select>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="editPersonalExperience">{{ $t("general.save") }}</v-btn>
                <v-btn text color="error" @click="deletePersonalExperience">{{ $t("general.delete") }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { THEMOVIEDB_API_KEY } from "../keys";
import { storage } from "../schmucklicloud";
import { Notifications } from "../notification";

export default {
  data() {
    return {
      id: 0,
      tvshow: null,

      row_id: null,
      edit_mode: false,

      watched_rating: null,
      watched_month: new Date().getMonth() + 1,
      watched_year: new Date().getFullYear(),

      months: [
        { text: this.$t("general.month.january"), value: 1 },
        { text: this.$t("general.month.february"), value: 2 },
        { text: this.$t("general.month.march"), value: 3 },
        { text: this.$t("general.month.april"), value: 4 },
        { text: this.$t("general.month.may"), value: 5 },
        { text: this.$t("general.month.june"), value: 6 },
        { text: this.$t("general.month.july"), value: 7 },
        { text: this.$t("general.month.august"), value: 8 },
        { text: this.$t("general.month.september"), value: 9 },
        { text: this.$t("general.month.october"), value: 10 },
        { text: this.$t("general.month.november"), value: 11 },
        { text: this.$t("general.month.december"), value: 12 }
      ]
    };
  },
  computed: {
    years() {
      var currentYear = new Date().getFullYear(),
        years = [];
      var startYear = 2000;
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      return years.reverse();
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.loadTVShow(this.id);
    this.loadPersonalExperience(this.id);
  },
  methods: {
    async loadTVShow(id) {
      var response = await axios({
        url:
          "https://api.themoviedb.org/3/tv/" +
          id +
          "?api_key=" +
          THEMOVIEDB_API_KEY +
          "&language=de"
      });
      if (response.status === 200) {
        this.tvshow = response.data;
      }
    },
    async loadPersonalExperience(id) {
      var response = await storage.get("tvshows", [
        {
          column: "tmdb_id",
          operator: "==",
          value: id
        }
      ]);

      if (response.isOK) {
        if (response.data) {
          var row = response.data[0];
          this.row_id = row.id;
          this.watched_rating = parseInt(row.data.watched_rating);
          this.watched_month = parseInt(row.data.watched_month);
          this.watched_year = parseInt(row.data.watched_year);
        } else {
          //
        }
      }
    },
    async savePersonalExperience() {
      var response = await storage.insert("tvshows", {
        tmdb_id: this.id,
        watched_rating: this.watched_rating,
        watched_month: this.watched_month,
        watched_year: this.watched_year,
        tvshow_title: this.tvshow.name,
        tvshow_first_air_date: this.tvshow.first_air_date,
        tvshow_poster_path: this.tvshow.poster_path === null ? "" : this.tvshow.poster_path
      });
      if (response.isOK) {
        Notifications.show(
          this.$t("tvshows.notification.saved_personal_experience")
        );
        this.row_id = response.data.id;
      } else {
        Notifications.show(response.message);
      }
    },
    async editPersonalExperience() {
      var response = await storage.update("tvshows", this.row_id, {
        watched_rating: this.watched_rating,
        watched_month: this.watched_month,
        watched_year: this.watched_year,
        tvshow_title: this.tvshow.name,
        tvshow_first_air_date: this.tvshow.first_air_date,
        tvshow_poster_path: this.tvshow.poster_path === null ? "" : this.tvshow.poster_path
      });
      if (response.isOK) {
        Notifications.show(
          this.$t("tvshows.notification.saved_personal_experience")
        );
        this.edit_mode = false;
      } else {
        Notifications.show(response.message);
      }
    },
    async deletePersonalExperience() {
      if (confirm(this.$t("tvshows.do_you_really_want_to_delete"))) {
        var response = await storage.delete("tvshows", this.row_id);
        if (response.isOK) {
          Notifications.show(this.$t("tvshows.notification.personal_experience_deleted"));
          this.goBack();
        } else {
          Notifications.show(response.message);
        }
      }
    },
    goBack() {
      this.$router.replace("/tvshows");
    }
  }
};
</script>

<style>
.backdrop {
  width: calc(100% + 2 * 12px);
  height: 300px;
  margin: -12px;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  filter: blur(5px);
}
.poster {
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.75);
}

.switch-center {
  display: flex;
  justify-content: center;
}
</style>